import React from "react";
import { Modal, Button } from "react-bootstrap";
import dateFormat from "dateformat";
import Iframe from "react-iframe";
import { Spinner } from "react-spinners-css";
import AOS from "aos";
import "aos/dist/aos.css";
class Latest_news extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: false, targetUrl: "", dataset: {}, loading: true };
  }

  hideSpinner = () => {
    this.setState({
      loading: false,
    });
  };

  setShow = (params) => {
    this.setState({ show: params });
  };

  handleClose = () => {
    this.setState({ show: false });
  };
  handleShow = (params) => {
    var renderUrl = params.target.getAttribute("data-param");
    this.setState({ loading: true });
    this.setState({ show: true });
    this.setState({ targetUrl: renderUrl });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount() {
    AOS.refresh();
  }
  render() {
    var answer = this.props.latest_news;
    //console.log(answer);
    let listItems = [];
    if (answer.length > 0) {
      let items = [];
      for (let k = 0; k < 3; k++) {
        items.push(answer[k]);
      }
      //console.log(items);
      listItems = Object.keys(items).map((i) => {
        //console.log(items[i]);

        if (typeof items[i] != "undefined") {
          return (
            <div
              className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 mb-2"
              key={i}
            >
              <button
                className="btn-tab aos-init aos-animate shadow p-3 mb-3  news_box bg-body rounded no-border "
                rel="gb_pageset[search_sites]"
                data-aos="fade-up"
                data-param={items[i]["fullURL"]}
                onClick={this.handleShow.bind(this)}
                key={i}
                data-aos-duration="1000"
              >
                <div
                  className="tab__date"
                  onClick={this.handleShow.bind(this)}
                  data-param={items[i]["fullURL"]}
                >
                  {dateFormat(items[i]["annDt"], "dd-mmm-yyyy")}
                </div>
                <div
                  className="tab__text"
                  onClick={this.handleShow.bind(this)}
                  data-param={items[i]["fullURL"]}
                >
                  {items[i]["annTitle"]}
                </div>
                <div
                  className="arrow"
                  onClick={this.handleShow.bind(this)}
                  data-param={items[i]["fullURL"]}
                ></div>
              </button>
            </div>
          );
        } else {
          return <span key={i}></span>;
        }
      });
    }
    //const resource = this.state.datasource;
    return (
      <span>
        {/* latest news */}
        <section className="position-relative d-flex align-items-center py-5 news-section ">
          <div className="container-fluid px-5">
            <div className="section-header">
              <h2 className="color-ci text-uppercase aos-init aos-animate latest_news_header">
                Latest Announcements
              </h2>
            </div>
            <div className="mt-4 row">{listItems}</div>
          </div>
        </section>
        {/* Latest news section end */}
        <br></br>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Announcement Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.loading ? (
              <Spinner
                className="loading text-center"
                name="three-bounce"
                color="#be97e8"
              />
            ) : null}
            <Iframe
              url={this.state.targetUrl}
              width="100%"
              height="600px"
              id="myId"
              className="myClassname"
              onLoad={this.hideSpinner}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </span>
    );
  }
}

export default Latest_news;
