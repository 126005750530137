import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import dateFormat from "dateformat";
import Iframe from "react-iframe";
import { Spinner } from "react-spinners-css";

const columns = [
  {
    name: "No",
    selector: (row) => row.serial,
    width: "80px",
    style: (row) => ({
      "max-width": "80px !important",
      "word-break": "break-word !important",
      "font-size": "15px",
      "text-align": "center",
      "padding-left": "20px !important",
      "padding-right": "10px !important",
    }),
  },
  {
    name: "Date",
    selector: (row) => dateFormat(row.annDt, "dd-mmm-yyyy"),
    width: "125px",
    style: (row) => ({
      "min-width": "125px !important",
      "max-width": "120px !important",
      "word-break": "break-word !important",
      "font-size": "15px",
      "text-align": "left",
      "padding-left": "0px !important",
      "padding-right": "10px !important",
    }),
  },
  {
    name: "Title",
    selector: (row) => row.annTitle,
    width: "75%",
    style: (row) => ({
      "max-width": "inherit !important",
      "word-break": "break-word !important",
      "font-size": "15px",
      color: "darkblue",
      "padding-top": "10px",
      "padding-bottom": "10px",
      "padding-left": "15px !important",
    }),
  },
];

function Table_display(props) {
  //console.log(props);
  const [annoucements, setAnnoucements] = useState({});
  const [page, setPage] = useState(1);
  const countPerPage = 30;
  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const [targetUrl, setTargetUrl] = useState(false);
  const [pending, setPending] = React.useState(true);
  //const [rows, setRows] = React.useState([]);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = (event, row) => {
    setShow(true);
    setTargetUrl(row.fullURL);
  };

  const getAnnouncementList = () => {
    var prev_params = props.others;
    var prev_param_options = prev_params.params;
    axios
      .get(
        `https://www.bursamalaysia.com/subscribers/option2?subscriber=${prev_param_options.subscriber}&option=${prev_param_options.option}&page=${page}&perPage=${prev_param_options.perPage}&type=${prev_param_options.type}`
      )
      .then((res) => {
        const records = res.data;
        const anns = records.anns;
        props.parentCallback(records.total);
        props.updateSample_new(records.anns);
        var s_count = (parseInt(page) - 1) * prev_param_options.perPage + 1;
        const newRecords = anns.map((item, index) => {
          return { ...item, serial: parseInt(s_count) + parseInt(index) };
        });
        records.anns = newRecords;
        setAnnoucements(records);
      })
      .catch((err) => {
        setAnnoucements({});
      });
  };

  useEffect(() => {
    getAnnouncementList();
    setLoading(true);
    const timeout = setTimeout(() => {
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [page]);
  const onRowClicked = (row, event) => {
    handleShow(event, row);
  };
  return (
    <div>
      <DataTable
        title=""
        columns={columns}
        data={annoucements.anns}
        highlightOnHover
        pagination
        paginationServer
        paginationTotalRows={annoucements.total}
        paginationPerPage={countPerPage}
        paginationComponentOptions={{
          noRowsPerPage: true,
        }}
        onChangePage={(page) => setPage(page)}
        responsive
        striped
        subHeaderWrap
        dense
        onRowClicked={onRowClicked}
        progressPending={pending}
      ></DataTable>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Announcement Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Spinner
              className="loading text-center"
              name="three-bounce"
              color="#be97e8"
            />
          ) : null}
          <Iframe
            url={targetUrl}
            width="100%"
            height="600px"
            id="myId"
            className="myClassname"
            onLoad={(loading) => setLoading(false)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Table_display;
