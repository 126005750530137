import React from "react";
import { Navbar } from "react-bootstrap";
import logo from "../../logo_tomei.png";
import logo3x from "../../tomei_logo_3x.png";

class Header extends React.Component {
  render() {
    return (
      <Navbar bg="" expand="lg" className="color-nav text-center">
        <Navbar.Brand href="#">
          <img
            src={logo}
            height="100%"
            className="align-top header_img img-responsive hidden-xs"
            alt="Tomei logo"
          />
          <img
            src={logo3x}
            width="55%"
            className="align-top header_img img-responsive visible-xs"
            alt="Tomei logo"
          />
        </Navbar.Brand>
      </Navbar>
    );
  }
}
export default Header;
