import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Home from "./components/sunmow/Home.js";
import { Container } from "react-bootstrap";
import "@fontsource/montserrat";

function App() {
  return (
    <Container className="p-0 container_layout" fluid>
      <Home />
      <footer className="page-footer font-small blue">
        <div className="footer-copyright text-left pl-2">
          Powered by: &nbsp;
          <a
            href="http://www.cornetresearch.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Cornet Research Sdn Bhd
          </a>
        </div>
      </footer>
      <br></br>
    </Container>
  );
}

export default App;
