import React from "react";
import { Accordion } from "react-bootstrap";
import TableDisplay from "./Table_display.js";
const List = (props) => {
  var data = React.useState(props.items);
  var answer = data[0];
  const [count, setCount] = React.useState(0);
  //console.log(answer[0]);
  const handleCallback = (count) => {
    setCount(count);
  };
  const updateSample_new = (test) => {
    props.updateSample(test);
  };
  return (
    <Accordion.Item
      eventKey={answer["key"]}
      key={answer["key"]}
      id={"list_" + answer["key"]}
    >
      <Accordion.Header>
        <span className="number_display">{answer["title"]}</span>{" "}
        <span className="acc_count">
          {" "}
          {count > 0 ? "( " + count + " )" : ""}{" "}
        </span>
      </Accordion.Header>
      <Accordion.Body>
        <TableDisplay
          others={answer}
          parentCallback={handleCallback}
          updateSample_new={updateSample_new}
        />
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default List;
