import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./Header.js";
import BodyTag from "./Body.js";

class Home extends React.Component {
  render() {
    return (
      <div>
         <Header></Header>
        <BodyTag></BodyTag>
      </div>
    );
  }
}
export default Home;
