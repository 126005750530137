import React from "react";
import currencyIcon from "../../icon-currency-up--white.png";
import revenueIcon from "../../icon-revenue--white.png";
import dividenIcon from "../../icon-dividen--white.png";
import valueIcon from "../../icon-value--white.png";
import arImage from "../../arimage_v7.png";
import AnReportView from "./Ann_report_view.js";
import { Modal, Button } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";

class Annual_report extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showArView: false };
  }

  handleViewARClick = () => {
    this.setState({ showArView: true });
  };

  handleCloseViewAR = () => {
    this.setState({ showArView: false });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount() {
    AOS.init();
  }
  render() {
    const profit_before_tax = this.props.profit_before_tax;
    const dividend_per_share = this.props.dividend_per_share;
    const total_revenue = this.props.total_revenue;
    const earnings_per_share = this.props.earnings_per_share;
    const financeTitle = this.props.financeTitle;
    const annualreportItems = this.props.annualreportItems;
    // console.log(annualreportItems);
    return (
      <span>
        <div
          id="annual-reports"
          className="mh60 position-relative d-flex align-items-center overflow-hidden"
        >
          <div className="header_background section-bg is-black-overlay"></div>
          {/* container code */}
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-10 d-flex flex-column justify-content-center mt-3 mt-md-0 pl-xl-5">
                <h2
                  className="text-uppercase text-white aos-init aos-animate header_img_text"
                  data-aos="zoom-in"
                  data-aos-duration="3500"
                >
                  KEY FINANCIAL INFORMATION
                  <br></br>
                  <span className="sub_header_img_text">{financeTitle}</span>
                </h2>
                <div
                  id="annual-reports-number"
                  className="grid-col-md-2 grid--line my-4"
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                >
                  <div className="grid-item">
                    <img
                      className="icon-stock mb-md-3 mb-lg-0"
                      src={currencyIcon}
                      alt="Profit"
                    ></img>
                    <div className="d-flex-flex-column text-white ml-0 ml-lg-3">
                      <h3 className="mb-0">
                        RM
                        <span
                          data-number-animate="{this.state.profit_before_tax}"
                          data-purecounter-start="0"
                          data-purecounter-end={profit_before_tax}
                          className="purecounter"
                        >
                          &nbsp;{profit_before_tax}
                        </span>
                      </h3>
                      <p className="mb-0">Profit before tax ('000)</p>
                    </div>
                  </div>
                  <div className="grid-item">
                    <img
                      className="icon-stock mb-md-3 mb-lg-0"
                      src={dividenIcon}
                      alt="Dividend"
                    ></img>
                    <div className="d-flex-flex-column text-white ml-0 ml-lg-3">
                      <h3 className="mb-0">
                        <span
                          data-number-animate="{this.state.dividend_per_share}"
                          data-number-decimal="2"
                          data-purecounter-start="0"
                          data-purecounter-end={dividend_per_share}
                          className="purecounter"
                        >
                          {dividend_per_share}&nbsp;
                        </span>
                        Sen
                      </h3>
                      <p className="mb-0">Dividend per share</p>
                    </div>
                  </div>

                  <div className="grid-item">
                    <img
                      className="icon-stock mb-md-3 mb-lg-0"
                      src={revenueIcon}
                      alt="Revenue"
                    ></img>
                    <div className="d-flex-flex-column text-white ml-0 ml-lg-3">
                      <h3 className="mb-0">
                        RM
                        <span
                          data-number-animate="{this.state.total_revenue}"
                          data-number-decimal="1"
                          data-purecounter-start="0"
                          data-purecounter-end={total_revenue}
                          className="purecounter"
                        >
                          &nbsp;{total_revenue}
                        </span>
                      </h3>
                      <p className="mb-0">Total revenue ('000)</p>
                    </div>
                  </div>
                  <div className="grid-item">
                    <img
                      className="icon-stock mb-md-3 mb-lg-0"
                      src={valueIcon}
                      alt="Assets"
                    ></img>
                    <div className="d-flex-flex-column text-white ml-0 ml-lg-3">
                      <h3 className="mb-0">
                        <span
                          data-number-animate="{this.state.earnings_per_share}"
                          data-number-decimal="2"
                          data-purecounter-start="0"
                          data-purecounter-end={earnings_per_share}
                          className="purecounter"
                        >
                          {earnings_per_share}&nbsp;
                        </span>
                        Sen
                      </h3>
                      <p className="mb-0"> Earnings per share</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-4 col-10  d-flex justify-content-center align-items-center mx-auto "
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                <img
                  className="img-fluid aos-init aos-animate an_left_img"
                  data-aos="fade-left"
                  data-aos-delay="100"
                  src={arImage}
                  alt="Annual Report"
                ></img>
              </div>
              <div className="col-md-8 col-sm-12  d-flex flex-column justify-content-center mt-5 mt-md-0 pl-xl-5"></div>
              <div
                className="col-md-4 col-sm-12  d-flex justify-content-center align-items-center mx-auto view_annual_report_div"
                onClick={this.handleViewARClick}
              >
                <a>View Annual Report</a>
              </div>
            </div>
          </div>

          {/* container code  end */}
        </div>

        <Modal show={this.state.showArView} onHide={this.handleCloseViewAR}>
          <Modal.Header closeButton>
            <Modal.Title>Annual Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AnReportView items={annualreportItems} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseViewAR}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </span>
    );
  }
}

export default Annual_report;
