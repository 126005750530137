import React, { useState, useEffect } from "react";
import TableDisplay from "./Table_display.js";
const Ann_report_view = (props) => {
  var data = React.useState(props.items);
  var answer = data[0];
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);

  const [count, setCount] = React.useState(0);
  //console.log(answer[0]);
  const handleCallback = (count) => {
    setCount(count);
  };
  const updateSample_new = (test) => {
    // props.updateSample(test);
  };
  /*const handleClose = () => {
    setShow(false);
  };
  const handleShow = (event, row) => {
    setShow(true);
  };*/
  useEffect(() => {
    // setLoading(true);
  }, [page]);
  return (
    <div>
      <TableDisplay
        others={answer}
        parentCallback={handleCallback}
        updateSample_new={updateSample_new}
      />
    </div>
  );
};

export default Ann_report_view;
