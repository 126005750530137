import React from "react";
import { Row, Accordion, Container, Form } from "react-bootstrap";
import Item from "./List.js";
import Lnews from "./Latest_news.js";
import Areport from "./Annual_report.js";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import ApexCharts from "apexcharts";

class Body extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      obj: {},
      items: [],
      is_first_time: 1,
      showFinance: 0,
      total_revenue: 0.0,
      dividend_per_share: 0.0,
      profit_before_tax: 0.0,
      earnings_per_share: 0.0,
      financeTitle: "",
      financeUrl: "",
      load_latest_news: true,
      load_chart_info: true,
      today_market_data: {
        regularMarketChange: "",
        regularMarketChangePercent: "",
        regularMarketDayHigh: "",
        regularMarketDayLow: "",
        regularMarketOpen: "",
        regularMarketPreviousClose: "",
        regularMarketPrice: "",
        regularMarketTime: "",
        shortName: "",
        symbol: "",
        currency: "MYR",
        last_sync: "",
        last_trade: "",
        regularMarketVolume: "",
      },
      stock_history: [],
      stock_history_data: [],
      curr_year_start: 0,
      trade_start_date: 0,
      series: [
        {
          data: [],
        },
      ],
      options: {
        chart: {
          id: "area-datetime",
          type: "area",
          height: 550,
          zoom: {
            autoScaleYaxis: true,
          },
          toolbar: {
            show: true,
            tools: {
              pan: false, // <== line to add,
              download: false,
            },
          },
        },
        stroke: {
          show: true,
          curve: "smooth",
          lineCap: "butt",
          colors: undefined,
          width: 1,
          dashArray: 0,
        },
        /*colors: ["#000000", "#e3e3e3"],*/
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: "hollow",
        },
        xaxis: {
          type: "datetime",
          min: new Date("01 Mar 2012").getTime(),
          tickAmount: 6,
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy",
          },

          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            var data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];

            return (
              "<table className='table table-striped'><tr className='tooltip_table'><th>&nbsp;&nbsp;" +
              new Date(data.x).toLocaleString("en-us", {
                day: "numeric",
                month: "short",
                year: "numeric",
              }) +
              "&nbsp;&nbsp;</th><th></th></tr>" +
              "<tbody>" +
              "<tr><td>&nbsp;&nbsp;Open : &nbsp;&nbsp;</td><td>&nbsp;&nbsp;" +
              data.open +
              "&nbsp;&nbsp;</td></tr>" +
              "<tr><td>&nbsp;&nbsp;High : &nbsp;&nbsp;</td><td>&nbsp;&nbsp;" +
              data.high +
              "&nbsp;&nbsp;</td></tr>" +
              "<tr><td>&nbsp;&nbsp;Low : &nbsp;&nbsp;</td><td>&nbsp;&nbsp;" +
              data.low +
              "&nbsp;&nbsp;</td></tr>" +
              "<tr><td>&nbsp;&nbsp;Close : &nbsp;&nbsp;</td><td>&nbsp;&nbsp;" +
              data.close +
              "&nbsp;&nbsp;</td></tr>" +
              "</tbody></table>"
            );
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100],
          },
        },
      },

      selection: "one_year",
      active_left_menu: "stock_info",
    };
    this.latest_news = [];
  }

  getGraphData() {}
  handleChange(event) {
    this.setState({
      active_left_menu: event.target.value,
    });
    // this.updateMenuData(event.target.value);
  }

  updateMenuData(menuItem) {
    this.setState({
      active_left_menu: menuItem,
    });
  }
  updateData(timeline) {
    this.setState({
      selection: timeline,
    });

    // console.log(history_meta);
    var trade_start_date = this.state.trade_start_date;

    var curr_yr_start = this.state.curr_year_start;
    switch (timeline) {
      case "one_month":
        var prev_date = new Date();
        var day = prev_date.getTime() - 30 * 24 * 60 * 60 * 1000;
        //prev_date.setDate(-30);
        prev_date.setTime(day);
        // New date: August 30, 2022
        //  console.log(new Date(prev_date).getTime());
        ApexCharts.exec(
          "area-datetime",
          "zoomX",
          new Date(prev_date).getTime(),
          new Date().getTime()
        );
        break;
      case "six_months":
        var prev_date = new Date();
        var day = prev_date.getTime() - 180 * 24 * 60 * 60 * 1000;
        //prev_date.setDate(-30);
        prev_date.setTime(day);
        ApexCharts.exec(
          "area-datetime",
          "zoomX",

          new Date(prev_date).getTime(),
          new Date().getTime()
        );
        break;
      case "one_year":
        var prev_date = new Date();
        var day = prev_date.getTime() - 365 * 24 * 60 * 60 * 1000;
        //prev_date.setDate(-30);
        prev_date.setTime(day);
        ApexCharts.exec(
          "area-datetime",
          "zoomX",
          new Date(prev_date).getTime(),
          new Date().getTime()
        );
        break;
      case "ytd":
        var prev_date = new Date(curr_yr_start);
        ApexCharts.exec(
          "area-datetime",
          "zoomX",
          new Date(prev_date).getTime(),
          new Date().getTime()
        );
        break;
      case "all":
        var prev_date = new Date(trade_start_date);

        ApexCharts.exec(
          "area-datetime",
          "zoomX",
          prev_date.getTime(),
          new Date().getTime()
        );
        break;
      default:
    }
  }

  setTotalRevenue = (params) => {
    this.setState({ total_revenue: params });
  };
  setDividendPerShare = (params) => {
    this.setState({ dividend_per_share: params });
  };
  setProfitBeforeTax = (params) => {
    this.setState({ profit_before_tax: params });
  };
  setEarningsPerShare = (params) => {
    this.setState({ earnings_per_share: params });
  };
  showHideFinance = (params) => {
    this.setState({ showFinance: params });
  };
  setFinanceTitle = (params) => {
    this.setState({ financeTitle: params });
  };

  addZeroes = (num) => {
    return num.toLocaleString("en", {
      useGrouping: false,
      minimumFractionDigits: 2,
    });
  };

  updateSample = (params) => {
    // console.log("child data ");
    // console.log(params);
    let latest_news1 = this.latest_news;
    for (let i = 0; i < params.length; i++) {
      const found = latest_news1.some((el) => el.annId === params[i].annId);
      if (!found) latest_news1.push(params[i]);
    }
    this.latest_news = latest_news1;
  };

  renderFinaneInfo = (params) => {
    const iframe = document.getElementById("mynewId");
    // const iWindow = iframe.contentWindow;
    // const iDocument = iWindow.document;
    if (iframe != null) {
      console.log(iframe.contentWindow.document);
    }
  };

  componentDidMount() {
    let self = this;
    //console.log("componentDidMount 1");
    var curT = new Date().getTime();
    axios
      // .get("http://localhost:8000/tomei_stock.json")
      .get(window.location.origin + "/finance/tomei_stock.json?t=" + curT)
      .then((res) => {
        // console.log(res);
        var data = typeof res.data != "undefined" ? res.data : "";
        if (data !== "") {
          self.state.today_market_data.regularMarketChangePercent =
            self.addZeroes(data.day_change_percent);
          self.state.today_market_data.regularMarketChange = self.addZeroes(
            data.day_change
          );
          self.state.today_market_data.regularMarketDayHigh = self.addZeroes(
            data.day_high
          );
          self.state.today_market_data.regularMarketDayLow = self.addZeroes(
            data.day_low
          );
          self.state.today_market_data.regularMarketOpen = self.addZeroes(
            data.day_open
          );
          self.state.today_market_data.regularMarketPreviousClose =
            self.addZeroes(data.pre_close);
          self.state.today_market_data.regularMarketPrice = self.addZeroes(
            data.share_price
          );
          self.state.today_market_data.regularMarketTime = self.addZeroes(
            data.last_trade_date
          );
          self.state.today_market_data.regularMarketVolume =
            data.volume.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            });
          self.state.today_market_data.shortName = data.shortName;
          self.state.today_market_data.symbol = data.symbol;
          self.state.today_market_data.currency = data.currency;
          self.state.today_market_data.last_sync = data.last_sync_date;
          self.state.today_market_data.last_trade = data.last_trade_date;
          /*var last_sync =
            new Date(data.regularMarketTime.timestamp * 1000).toLocaleString(
              "en-us",
              {
                month: "short",
                year: "numeric",
                day: "numeric",
                hours: "numeric",
                minutes: "numeric",
              }
            ) +
            " " +
            new Date(
              data.regularMarketTime.timestamp * 1000
            ).toLocaleTimeString();
          self.state.today_market_data.last_sync = last_sync;*/
        }
        // console.log(self.state.today_market_data);
      })
      .catch((err) => {});
    var curT = new Date().getTime();
    axios
      //.get("http://localhost:8000/tomei_stock_history.json")
      .get(
        window.location.origin + "/finance/tomei_stock_history.json?t=" + curT
      )
      .then((res) => {
        // console.log(res);
        self.state.load_chart_info = false;
        var data = typeof res.data != "undefined" ? res.data : "";
        if (data !== "") {
          self.state.stock_history_data = data;
          var dataList = [];
          var hist = data;
          var keys = Object.keys(hist);
          var last = keys[keys.length - 1];
          var last_sync =
            new Date(last * 1000).toLocaleString("en-us", {
              month: "short",
              year: "numeric",
              day: "numeric",
              hours: "numeric",
              minutes: "numeric",
            }) +
            " " +
            new Date(last * 1000).toLocaleTimeString();
          //self.state.today_market_data.last_sync = last_sync;
          var categories = [];
          var a = 0;
          var first_date = "";
          for (let i in hist) {
            hist[i].date_utc = new Date(hist[i].date).getTime();
            if (a === last) {
              first_date = hist[i].date_utc;
              a++;
            }
            var tmp = hist[i].date_utc;
            dataList.push({
              x: tmp,
              y: hist[i].adj_close,
              open: hist[i].open,
              high: hist[i].high,
              close: hist[i].close,
              low: hist[i].low,
            });
            categories.push(
              new Date(tmp).toLocaleString("en-us", {
                month: "short",
                year: "numeric",
                day: "numeric",
              })
            );
          }
          self.state.stock_history = dataList;
          self.state.series = [
            {
              // name: "volume ( " + data.meta.currency + " )",
              data: dataList,
            },
          ];
          self.state.trade_start_date = first_date;
          var current_yr_start =
            new Date(new Date().getFullYear(), 0, 1).getTime() / 1000;
          var curr_yr_start = current_yr_start;
          self.state.curr_year_start = curr_yr_start * 1000;
          //console.log(categories);
          self.state.options.xaxis = {
            type: "datetime",
            min: new Date(first_date).getTime(),
            // tickAmount: 10,
            // categories: categories,
            labels: {
              datetimeFormatter: {
                year: "yyyy",
                month: "MMM 'yy",
                day: "dd MMM",
                hour: "HH:mm",
              },
            },
          };
        } else {
          console.log("failed to load ");
        }
      })
      .catch((err) => {
        console.log("failed to load ", err.message);
        self.state.load_chart_info = false;
      });

    axios
      .get(
        `https://www.bursamalaysia.com/subscribers/option2?subscriber=7230&option=option2&page=1&perPage=1&type=FA,FRCO`
      )
      .then((res) => {
        const records = res.data;
        const anns = records.anns;
        if (anns.length > 0) {
          self.showHideFinance(1);
          self.state.financeUrl = anns[0].fullURL;
          getFinanceInfo(anns[0].fullURL);
        }
      })
      .catch((err) => {});
    async function getFinanceInfo(url) {
      try {
        fetch(window.location.origin + "/finance/get_finance_info.php", {
          //fetch("http://localhost:8000/get_finance_info.php", {
          method: "POST",
          body: JSON.stringify({ url: url }),
        })
          .then(function (response) {
            // console.log(response);
            // console.log(url + " -> " + response.ok);
            if (response.ok) {
              self.state.load_latest_news = false;
              return response.text();
            }
            throw new Error("Error message.");
          })
          .then(function (data) {
            var res = JSON.parse(data);

            var fin_title = res.sub_title;
            self.setFinanceTitle(fin_title);

            var t_revenue = res.total_revenue;
            self.setTotalRevenue(t_revenue);

            var pr_b_tax = res.profit_before_tax;
            self.setProfitBeforeTax(pr_b_tax);

            var dividend_share_amount = res.dividend_per_share;
            self.setDividendPerShare(dividend_share_amount);

            var earning_share_amount = res.earnings_per_share;
            self.setEarningsPerShare(earning_share_amount);
            self.state.load_latest_news = false;
            // console.log();
            // this.setState({ content: data });
            /*var parser = new DOMParser();

            // Parse the text
            var doc = parser.parseFromString(data, "text/html");

            var fin_title =
              doc.querySelector(".formTableHeader div h4 b:nth-of-type(2)")
                .innerHTML +
              " " +
              doc.querySelector(".formTableHeader div h4 b:nth-of-type(3)")
                .innerHTML;
            self.setFinanceTitle(fin_title);
            // You can now even select part of that html as you would in the regular DOM
            // Example:
            // var docArticle = doc.querySelector('article').innerHTML;

            var ven_table =
              doc.querySelector(".ven_table").children[0].children[5].innerHTML;
            var tableInfo = parser.parseFromString(ven_table, "text/html");
            var t_revenue = tableInfo.querySelector("div").innerHTML;
            self.setTotalRevenue(t_revenue);

            var ven_table_new =
              doc.querySelector(".ven_table").children[0].children[6].innerHTML;
            var tableInfo_new = parser.parseFromString(
              ven_table_new,
              "text/html"
            );
            var pr_b_tax = tableInfo_new.querySelector("div").innerHTML;

            self.setProfitBeforeTax(pr_b_tax);

            var dividend_share =
              doc.querySelector(".ven_table").children[0].children[10]
                .innerHTML;
            var dividend_share_new = parser.parseFromString(
              dividend_share,
              "text/html"
            );
            var dividend_share_amount =
              dividend_share_new.querySelector("div").innerHTML;

            self.setDividendPerShare(dividend_share_amount);

            var earning_share =
              doc.querySelector(".ven_table").children[0].children[9].innerHTML;
            var earning_share_new = parser.parseFromString(
              earning_share,
              "text/html"
            );
            var earning_share_amount =
              earning_share_new.querySelector("div").innerHTML;

            self.setEarningsPerShare(earning_share_amount);*/
          })
          .catch(function (err) {
            console.log("failed to load ", url, err.message);
            self.state.load_latest_news = false;
          });
      } catch (err) {
        console.log(err);
      }
    }
    /* new PureCounter({
      // Setting that can't' be overriden on pre-element
      selector: ".purecounter", // HTML query selector for spesific element

      // Settings that can be overridden on per-element basis, by `data-purecounter-*` attributes:
      start: 0, // Starting number [uint]
      end: 100, // End number [uint]
      duration: 2, // The time in seconds for the animation to complete [seconds]
      delay: 10, // The delay between each iteration (the default of 10 will produce 100 fps) [miliseconds]
      once: false, // Counting at once or recount when the element in view [boolean]
      pulse: false, // Repeat count for certain time [boolean:false|seconds]
      decimals: 2, // How many decimal places to show. [uint]
      legacy: true, // If this is true it will use the scroll event listener on browsers
      filesizing: false, // This will enable/disable File Size format [boolean]
      currency: false, // This will enable/disable Currency format. Use it for set the symbol too [boolean|char|string]
      formater: "us-US", // Number toLocaleString locale/formater, by default is "en-US" [string|boolean:false]
      separator: true, // This will enable/disable comma separator for thousands. Use it for set the symbol too [boolean|char|string]
    });*/
  }
  componentDidUpdate() {
    //  const { chartId, seriesData } = this.props;
    //here it overrides the chart series with seriesData from props
    // apexchart.exec(chartId, "updateSeries", seriesData);
    let self = this;

    if (self.state.is_first_time < 3) {
      //console.log(self.state.is_first_time);
      var curr_prev_date = new Date();
      var curr_day = curr_prev_date.getTime() - 365 * 24 * 60 * 60 * 1000;
      curr_prev_date.setTime(curr_day);
      ApexCharts.exec(
        "area-datetime",
        "zoomX",
        new Date(curr_prev_date).getTime(),
        new Date().getTime()
      );
      self.state.is_first_time = self.state.is_first_time + 1;
    }
  }

  render() {
    var obj = {};
    obj[0] = {
      title: "Additional Listing Announcement / Subdivision of Shares",
      content: "",
      count: 0,
      key: 0,
      params: {
        subscriber: "7230",
        option: "option2",
        page: 1,
        perPage: 30,
        type: "AL,ALCO",
      },
    };
    obj[1] = {
      title: "Annual Audited Account",
      content: "",
      count: 0,
      key: 1,
      params: {
        subscriber: "7230",
        option: "option2",
        page: 1,
        perPage: 30,
        type: "AA,AACO",
      },
    };
    obj[2] = {
      title: "Annual Report",
      content: "",
      count: 0,
      key: 2,
      params: {
        subscriber: "7230",
        option: "option2",
        page: 1,
        perPage: 30,
        type: "AR,ARCO",
      },
    };
    obj[3] = {
      title: "Change of Corporate Information",
      content: "",
      count: 0,
      key: 3,
      params: {
        subscriber: "7230",
        option: "option2",
        page: 1,
        perPage: 30,
        type: "CI,COCI",
      },
    };
    obj[4] = {
      title: "Changes in Shareholdings",
      content: "",
      count: 0,
      key: 4,
      params: {
        subscriber: "7230",
        option: "option2",
        page: 1,
        perPage: 30,
        type: "SH,CHSH",
      },
    };
    obj[5] = {
      title: "Circular / Notice to Shareholders",
      content: "",
      count: 0,
      key: 5,
      params: {
        subscriber: "7230",
        option: "option2",
        page: 1,
        perPage: 30,
        type: "CS,CSCO",
      },
    };
    obj[6] = {
      title:
        "Dealings in Listed Securities (Chapter 14 of Listing Requirements)",
      content: "",
      count: 0,
      key: 6,
      params: {
        subscriber: "7230",
        option: "option2",
        page: 1,
        perPage: 30,
        type: "DRCO",
      },
    };
    obj[7] = {
      title: "Delisting of Securities",
      content: "",
      count: 0,
      key: 7,
      params: {
        subscriber: "7230",
        option: "option2",
        page: 1,
        perPage: 30,
        type: "DLCO",
      },
    };
    obj[8] = {
      title: "Entitlements",
      content: "",
      count: 0,
      key: 8,
      params: {
        subscriber: "7230",
        option: "option2",
        page: 1,
        perPage: 30,
        type: "EA,ENCO",
      },
    };
    obj[9] = {
      title: "Expiry / Maturity / Termination of Securities",
      content: "",
      count: 0,
      key: 9,
      params: {
        subscriber: "7230",
        option: "option2",
        page: 1,
        perPage: 30,
        type: "ES,EMCO",
      },
    };
    obj[10] = {
      title: "Financial Results",
      content: "",
      count: 0,
      key: 10,
      params: {
        subscriber: "7230",
        option: "option2",
        page: 1,
        perPage: 30,
        type: "FA,FRCO",
      },
    };
    obj[11] = {
      title: "General Announcement",
      content: "",
      count: 0,
      key: 11,
      params: {
        subscriber: "7230",
        option: "option2",
        page: 1,
        perPage: 30,
        type: "GA,GACO",
      },
    };
    obj[12] = {
      title: "General Meetings",
      content: "",
      count: 0,
      key: 12,
      params: {
        subscriber: "7230",
        option: "option2",
        page: 1,
        perPage: 30,
        type: "GM,MECO",
      },
    };
    obj[13] = {
      title: "IPO Announcement / Admission to LEAP Market Announcement",
      content: "",
      count: 0,
      key: 13,
      params: {
        subscriber: "7230",
        option: "option2",
        page: 1,
        perPage: 30,
        type: "IO,IPOA",
      },
    };
    obj[14] = {
      title: "Important Relevant Dates for Renounceable Rights",
      content: "",
      count: 0,
      key: 14,
      params: {
        subscriber: "7230",
        option: "option2",
        page: 1,
        perPage: 30,
        type: "TR",
      },
    };
    obj[15] = {
      title: "Investor Alert",
      content: "",
      count: 0,
      key: 15,
      params: {
        subscriber: "7230",
        option: "option2",
        page: 1,
        perPage: 30,
        type: "IA,IACO",
      },
    };
    obj[16] = {
      title: "Listing Circulars",
      content: "",
      count: 0,
      key: 16,
      params: {
        subscriber: "7230",
        option: "option2",
        page: 1,
        perPage: 30,
        type: "LC,LCCO",
      },
    };
    obj[17] = {
      title: "Listing Information and Profile",
      content: "",
      count: 0,
      key: 17,
      params: {
        subscriber: "7230",
        option: "option2",
        page: 1,
        perPage: 30,
        type: "IP,LICO",
      },
    };
    obj[18] = {
      title: "Reply to Query",
      content: "",
      count: 0,
      key: 18,
      params: {
        subscriber: "7230",
        option: "option2",
        page: 1,
        perPage: 30,
        type: "RQ,RQCO",
      },
    };
    obj[19] = {
      title: "Shares Buy Back",
      content: "",
      count: 0,
      key: 19,
      params: {
        subscriber: "7230",
        option: "option2",
        page: 1,
        perPage: 30,
        type: "SB,SBBA",
      },
    };
    obj[20] = {
      title: "Take-over Offer",
      content: "",
      count: 0,
      key: 20,
      params: {
        subscriber: "7230",
        option: "option2",
        page: 1,
        perPage: 30,
        type: "TECO",
      },
    };
    obj[21] = {
      title: "Transfer of Listing",
      content: "",
      count: 0,
      key: 21,
      params: {
        subscriber: "7230",
        option: "option2",
        page: 1,
        perPage: 30,
        type: "TL,TRFL",
      },
    };
    obj[22] = {
      title: "Unusual Market Activity",
      content: "",
      count: 0,
      key: 22,
      params: {
        subscriber: "7230",
        option: "option2",
        page: 1,
        perPage: 30,
        type: "UMA,UMCO",
      },
    };

    // var newObj = obj;
    var fin_info = {};
    fin_info[0] = obj[10];

    var aun_audit_info = {};
    aun_audit_info[0] = obj[1];

    var entitle_info = {};
    entitle_info[0] = obj[8];
    entitle_info[1] = obj[14];

    var anu_reports = {};
    anu_reports[0] = obj[2];

    var gen_meeting_info = {};
    gen_meeting_info[0] = obj[12];

    var chng_share_info = {};
    chng_share_info[0] = obj[4];
    chng_share_info[1] = obj[6];
    chng_share_info[2] = obj[19];

    var cir_notice_info = {};
    cir_notice_info[0] = obj[5];
    cir_notice_info[1] = obj[16];
    cir_notice_info[2] = obj[17];
    cir_notice_info[3] = obj[0];
    cir_notice_info[4] = obj[9];
    cir_notice_info[5] = obj[7];

    var inv_alert_info = {};
    inv_alert_info[0] = obj[22];
    inv_alert_info[1] = obj[18];
    inv_alert_info[2] = obj[15];

    var others_info = {};
    others_info[0] = obj[11];
    others_info[1] = obj[3];
    others_info[2] = obj[13];
    others_info[3] = obj[20];
    others_info[4] = obj[21];

    /*const listItems = Object.keys(newObj).map((i) => {
      return (
        <Item items={newObj[i]} key={i} updateSample={this.updateSample} />
      );
    });*/

    const fin_info_items = Object.keys(fin_info).map((i) => {
      return (
        <Item items={fin_info[i]} key={i} updateSample={this.updateSample} />
      );
    });

    const aun_audit_info_items = Object.keys(aun_audit_info).map((i) => {
      return (
        <Item
          items={aun_audit_info[i]}
          key={i}
          updateSample={this.updateSample}
        />
      );
    });

    const entitle_info_items = Object.keys(entitle_info).map((i) => {
      return (
        <Item
          items={entitle_info[i]}
          key={i}
          updateSample={this.updateSample}
        />
      );
    });

    const anu_reports_items = Object.keys(anu_reports).map((i) => {
      return (
        <Item items={anu_reports[i]} key={i} updateSample={this.updateSample} />
      );
    });

    const gen_meeting_info_items = Object.keys(gen_meeting_info).map((i) => {
      return (
        <Item
          items={gen_meeting_info[i]}
          key={i}
          updateSample={this.updateSample}
        />
      );
    });

    const chng_share_info_items = Object.keys(chng_share_info).map((i) => {
      return (
        <Item
          items={chng_share_info[i]}
          key={i}
          updateSample={this.updateSample}
        />
      );
    });

    const cir_notice_info_items = Object.keys(cir_notice_info).map((i) => {
      return (
        <Item
          items={cir_notice_info[i]}
          key={i}
          updateSample={this.updateSample}
        />
      );
    });

    const inv_alert_info_items = Object.keys(inv_alert_info).map((i) => {
      return (
        <Item
          items={inv_alert_info[i]}
          key={i}
          updateSample={this.updateSample}
        />
      );
    });

    const others_info_items = Object.keys(others_info).map((i) => {
      return (
        <Item items={others_info[i]} key={i} updateSample={this.updateSample} />
      );
    });

    var annualreportObj = obj[2];
    /*const annualreportItems = Object.keys(annualreportObj).map((i) => {
      return <AnReportView items={annualreportObj[i]} key={i} />;
    });*/
    var htmlObject = "";

    // console.log(htmlObject);
    var news_info = this.latest_news;
    const numDescending = [...news_info].sort((a, b) => b.annId - a.annId);

    return (
      <Row>
        <span className="hiddenIframe">{htmlObject}</span>
        <Areport
          profit_before_tax={this.state.profit_before_tax}
          dividend_per_share={this.state.dividend_per_share}
          total_revenue={this.state.total_revenue}
          earnings_per_share={this.state.earnings_per_share}
          financeTitle={this.state.financeTitle}
          annualreportItems={annualreportObj}
        ></Areport>
        <br></br>
        <div className="">
          {/*}
                <div className="company_announcement_title mt-1">
                  <h5>
                    <strong>Company Announcements </strong>
                  </h5>
                </div>
                {*/}
          {this.state.load_latest_news ? (
            <div className="px-8">
              <section className="position-relative d-lg-flex d-md-flex d-lg-flex align-items-center news-section mb-5">
                <div className="sub_container grid-col-3 mt-4">
                  <div className="post">
                    <div className="line"></div>
                    <div className="line"></div>
                  </div>
                </div>
                <div className="sub_container grid-col-3 mt-4">
                  <div className="post">
                    <div className="line"></div>
                    <div className="line"></div>
                  </div>
                </div>
                <div className="sub_container grid-col-3 mt-4">
                  <div className="post">
                    <div className="line"></div>
                    <div className="line"></div>
                  </div>
                </div>
              </section>
            </div>
          ) : (
            <Lnews
              latest_news={numDescending}
              data-aos="fade-up"
              data-aos-delay="1000"
            ></Lnews>
          )}
        </div>
        <br></br>
        <Container className="pt-4 px-4 container-fluid body-box">
          <div className="row">
            <div className="sidebar sidebar-main col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <div className="d-none d-sm-inline">
                <Accordion className="">
                  <Accordion.Item
                    eventKey="0"
                    className={
                      this.state.active_left_menu === "stock_info"
                        ? "no-sub activeM"
                        : "no-sub"
                    }
                    onClick={() => this.updateMenuData("stock_info")}
                  >
                    <Accordion.Header>Stock Information</Accordion.Header>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey="1"
                    className={
                      this.state.active_left_menu === "fin_info"
                        ? "no-sub activeM"
                        : "no-sub"
                    }
                    onClick={() => this.updateMenuData("fin_info")}
                  >
                    <Accordion.Header>Financial Results</Accordion.Header>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey="2"
                    className={
                      this.state.active_left_menu === "aun_audit_info"
                        ? "no-sub activeM"
                        : "no-sub"
                    }
                    onClick={() => this.updateMenuData("aun_audit_info")}
                  >
                    <Accordion.Header>Annual Audited Account</Accordion.Header>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey="3"
                    className={
                      this.state.active_left_menu === "entitlements"
                        ? "no-sub activeM"
                        : "no-sub"
                    }
                    onClick={() => this.updateMenuData("entitlements")}
                  >
                    <Accordion.Header>Entitlements</Accordion.Header>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey="4"
                    className={
                      this.state.active_left_menu === "anu_reports"
                        ? "no-sub activeM"
                        : "no-sub"
                    }
                    onClick={() => this.updateMenuData("anu_reports")}
                  >
                    <Accordion.Header>Annual Report</Accordion.Header>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey="5"
                    className={
                      this.state.active_left_menu === "gen_meetings"
                        ? "no-sub activeM"
                        : "no-sub"
                    }
                    onClick={() => this.updateMenuData("gen_meetings")}
                  >
                    <Accordion.Header>General Meetings</Accordion.Header>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey="6"
                    className={
                      this.state.active_left_menu === "chng_share"
                        ? "no-sub activeM"
                        : "no-sub"
                    }
                    onClick={() => this.updateMenuData("chng_share")}
                  >
                    <Accordion.Header>Changes In Shareholding</Accordion.Header>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey="7"
                    className={
                      this.state.active_left_menu === "cir_notices"
                        ? "no-sub activeM"
                        : "no-sub"
                    }
                    onClick={() => this.updateMenuData("cir_notices")}
                  >
                    <Accordion.Header>Circulars / Notices</Accordion.Header>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey="8"
                    className={
                      this.state.active_left_menu === "inv_alert"
                        ? "no-sub activeM"
                        : "no-sub"
                    }
                    onClick={() => this.updateMenuData("inv_alert")}
                  >
                    <Accordion.Header>Investor Alert </Accordion.Header>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey="9"
                    className={
                      this.state.active_left_menu === "other_m"
                        ? "no-sub activeM"
                        : "no-sub"
                    }
                    onClick={() => this.updateMenuData("other_m")}
                  >
                    <Accordion.Header>Others</Accordion.Header>
                  </Accordion.Item>
                  {/*}
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Menu 3</Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <li className="pb-2">Sub Menu 1</li>
                        <li className="pb-2">Sub Menu 2</li>
                        <li className="pb-2">Sub Menu 3</li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  { */}
                </Accordion>
              </div>
            </div>
            <div className="column main col-lg-9 col-md-9 col-sm-9 col-xs-12 pull-right rightblock">
              <div className="d-md-none d-lg-none d-xl-none  pb-5 px-2">
                <Form.Select
                  aria-label="Default select example"
                  className="mobile_view_menu"
                  onChange={this.handleChange.bind(this)}
                >
                  <option value="stock_info">Stock Information</option>
                  <option value="fin_info">Financial Results</option>
                  <option value="aun_audit_info">Annual Audited Account</option>
                  <option value="entitlements">Entitlements</option>
                  <option value="anu_reports">Annual Report</option>
                  <option value="gen_meetings">General Meetings</option>
                  <option value="chng_share">Changes In Shareholdings</option>
                  <option value="cir_notices">Circulars / Notices</option>
                  <option value="inv_alert">Investor Alert</option>
                  <option value="other_m">Others</option>
                </Form.Select>
              </div>

              <div
                className={
                  this.state.active_left_menu === "stock_info"
                    ? "stock_info_box"
                    : "stock_info_box hidden"
                }
              >
                {/* stock information start */}
                {this.state.load_chart_info ? (
                  <span className="px-3 pb-5 pt-5"> Loading...</span>
                ) : (
                  <div
                    className="shadow-lg p-3 mb-3 bg-body rounded stock_info aos-init aos-animate"
                    data-aos="fade-up"
                  >
                    <div className="row">
                      <div className="col-sm-4 col-md-4 left-col col-xs-12">
                        <br></br>
                        <br></br>
                        <br></br>
                        <span className="share_price_label">
                          Share Price ({this.state.today_market_data.currency})
                        </span>
                        <br></br>{" "}
                        <span className="last_update_label">
                          Last Traded :&nbsp;&nbsp;
                          {this.state.today_market_data.last_trade}
                        </span>
                      </div>
                      <div className="col-sm-4 col-md-4 middle-col col-xs-12 pt-5">
                        <h3>
                          <b>{this.state.today_market_data.shortName}</b>
                          &nbsp;
                          <span className="stock_code">
                            ({this.state.today_market_data.symbol})
                          </span>
                        </h3>
                        <h1>
                          <b>
                            <span
                              className={
                                this.state.today_market_data
                                  .regularMarketPrice <
                                this.state.today_market_data
                                  .regularMarketPreviousClose
                                  ? "red"
                                  : "green"
                              }
                            >
                              {this.state.today_market_data.regularMarketPrice}
                            </span>
                          </b>
                        </h1>
                      </div>
                      <div className="col-sm-4 col-md-4 right-col col-xs-12">
                        <table className="">
                          <tbody>
                            <tr>
                              <td>Open</td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;</td>
                              <td>
                                <span
                                  className={
                                    this.state.today_market_data
                                      .regularMarketOpen <
                                    this.state.today_market_data
                                      .regularMarketPreviousClose
                                      ? "red"
                                      : "green"
                                  }
                                >
                                  {
                                    this.state.today_market_data
                                      .regularMarketOpen
                                  }
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>Day High</td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;</td>
                              <td>
                                <span
                                  className={
                                    this.state.today_market_data
                                      .regularMarketDayHigh <
                                    this.state.today_market_data
                                      .regularMarketPreviousClose
                                      ? "red"
                                      : "green"
                                  }
                                >
                                  {
                                    this.state.today_market_data
                                      .regularMarketDayHigh
                                  }
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>Day Low</td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;</td>
                              <td>
                                <span
                                  className={
                                    this.state.today_market_data
                                      .regularMarketDayLow <
                                    this.state.today_market_data
                                      .regularMarketPreviousClose
                                      ? "red"
                                      : "green"
                                  }
                                >
                                  {
                                    this.state.today_market_data
                                      .regularMarketDayLow
                                  }
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>Change</td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;</td>
                              <td>
                                <span
                                  className={
                                    this.state.today_market_data
                                      .regularMarketChange < 0
                                      ? "red"
                                      : "green"
                                  }
                                >
                                  {
                                    this.state.today_market_data
                                      .regularMarketChange
                                  }
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>Change (%)</td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;</td>
                              <td>
                                <span
                                  className={
                                    this.state.today_market_data
                                      .regularMarketChangePercent < 0
                                      ? "red"
                                      : "green"
                                  }
                                >
                                  {
                                    this.state.today_market_data
                                      .regularMarketChangePercent
                                  }
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>Previous Close </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;</td>
                              <td>
                                <span>
                                  {
                                    this.state.today_market_data
                                      .regularMarketPreviousClose
                                  }
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>Volume (shares) </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;</td>
                              <td>
                                <span>
                                  {
                                    this.state.today_market_data
                                      .regularMarketVolume
                                  }
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <br />
                        <span className="last_update_label">
                          Data Refreshed :&nbsp;&nbsp;
                          {this.state.today_market_data.last_sync}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {/* stock information end */}
                <div className="px-3 pb-5 pt-5">
                  <div id="chart">
                    <div className="toolbar">
                      <button
                        id="one_month"
                        onClick={() => this.updateData("one_month")}
                        className={
                          this.state.selection === "one_month" ? "active" : ""
                        }
                      >
                        1M
                      </button>
                      &nbsp;
                      <button
                        id="six_months"
                        onClick={() => this.updateData("six_months")}
                        className={
                          this.state.selection === "six_months" ? "active" : ""
                        }
                      >
                        6M
                      </button>
                      &nbsp;
                      <button
                        id="one_year"
                        onClick={() => this.updateData("one_year")}
                        className={
                          this.state.selection === "one_year" ? "active" : ""
                        }
                      >
                        1Y
                      </button>
                      &nbsp;
                      <button
                        id="ytd"
                        onClick={() => this.updateData("ytd")}
                        className={
                          this.state.selection === "ytd" ? "active" : ""
                        }
                      >
                        YTD
                      </button>
                    </div>
                    <br></br>
                    <div id="chart-timeline pt-5">
                      {this.state.load_chart_info ? (
                        <span className=""> Loading...</span>
                      ) : (
                        <ReactApexChart
                          options={this.state.options}
                          series={this.state.series}
                          type="area"
                          height={350}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* financial information */}
              <div
                className={
                  this.state.active_left_menu === "fin_info" ? "" : "hidden"
                }
              >
                <Accordion className="pb-5 px-2 acc_list">
                  {fin_info_items}
                </Accordion>
              </div>
              <div
                className={
                  this.state.active_left_menu === "aun_audit_info"
                    ? ""
                    : "hidden"
                }
              >
                <Accordion className="pb-5 px-2 acc_list">
                  {aun_audit_info_items}
                </Accordion>
              </div>
              <div
                className={
                  this.state.active_left_menu === "entitlements" ? "" : "hidden"
                }
              >
                <Accordion className="pb-5 px-2 acc_list">
                  {entitle_info_items}
                </Accordion>
              </div>
              <div
                className={
                  this.state.active_left_menu === "anu_reports" ? "" : "hidden"
                }
              >
                <Accordion className="pb-5 px-2 acc_list">
                  {anu_reports_items}
                </Accordion>
              </div>
              <div
                className={
                  this.state.active_left_menu === "gen_meetings" ? "" : "hidden"
                }
              >
                <Accordion className="pb-5 px-2 acc_list">
                  {gen_meeting_info_items}
                </Accordion>
              </div>
              <div
                className={
                  this.state.active_left_menu === "chng_share" ? "" : "hidden"
                }
              >
                <Accordion className="pb-5 px-2 acc_list">
                  {chng_share_info_items}
                </Accordion>
              </div>
              <div
                className={
                  this.state.active_left_menu === "cir_notices" ? "" : "hidden"
                }
              >
                <Accordion className="pb-5 px-2 acc_list">
                  {cir_notice_info_items}
                </Accordion>
              </div>
              <div
                className={
                  this.state.active_left_menu === "inv_alert" ? "" : "hidden"
                }
              >
                <Accordion className="pb-5 px-2 acc_list">
                  {inv_alert_info_items}
                </Accordion>
              </div>
              <div
                className={
                  this.state.active_left_menu === "other_m" ? "" : "hidden"
                }
              >
                <Accordion className="pb-5 px-2 acc_list">
                  {others_info_items}
                </Accordion>
              </div>
              {/* financial information end */}
              {/* 
              <Accordion className="pb-5 px-2 acc_list"> {listItems}</Accordion>
              */}
            </div>
          </div>
          <br></br>
        </Container>

        <br></br>
      </Row>
    );
  }
}
export default Body;
